import React from "react";
import logo_tipddy from "../../assets/logo-tipddy.png";
import { useAuth } from "../../provider/auth";
import { Button } from "@mui/material";

export const InatransPlayerCompleted = ({url_name = ''}) => {
  const { auth } = useAuth();
  return (
    <div
      className="d-flex h-100 text-center text-white bg-dark"
      style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}
    >
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <main className="px-3">
          <h1>Este modulo ya se encuentra completado</h1>
          <p className="lead w-25 mt-3">
            Para ingresar a otros modulos, regresa a campus virtual{" "}
            <a
              style={{ textDecoration: "underline" }}
              href={`http://elearning.inatrans.cl/course/view.php?id=${auth.cookies.course_id}`}
            >
              <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                aquí
              </Button>

            </a>
          </p>
          {url_name !== "inatrans-evaluacion-psicologica" && <p className="lead w-25 mt-3">
            Para continuar al siguiente módulo{" "}
            <a
              style={{ textDecoration: "underline" }}
              href={`https://tipconsola.inatrans.cl/?url_name=${url_name}`}
            >
              <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                aquí
              </Button>

            </a>
          </p>}
          {(auth.cookies.user_email === "agu.quiroz%40tipddy.cl" || auth.cookies.user_email === "agu.quiroz@tipddy.cl") && <p className="lead w-25 mt-3">
            Para regresar a la lista de actividades:{" "}
            <a
              style={{ textDecoration: "underline",marginLeft: "5px" }}
              //href={`https://tipconsola.inatrans.cl/?config=modulelist&course_id=123123123&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl&url_name=modules`}
              href={`https://tipconsola.inatrans.cl/?config=modulelist&course_id=123123123&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl&url_name=modules`}
            >
              <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                aquí
              </Button>

            </a>
          </p>}
        </main>

        <footer
          className="mt-auto text-white-50 d-flex "
          style={{ justifyContent: "center" }}
        >
          <img src={logo_tipddy} alt="..." className="img-rounded" />
          <p>Copyright Powered by Tipddy</p>
        </footer>
      </div>
    </div>
  );
};
