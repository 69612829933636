import { URL_NAMES, URL_NAMES_V2 } from "./state";

export const useMobile = () => {
  const isMobile = window.innerWidth < 900;
  return [isMobile];
};

/**
 *
 * @param {correctAnswers} as  cq
 * @param {moduleName} as  name
 */
export const defineApproved = ({ ca = "", name, final = [] }) => {
  // console.log({ final });
  switch (name) {
    case URL_NAMES_V2.MODULO_I: //  Identificacion de Riesgo / Imagenes
      if (ca >= 18) return "Cumple";
      if (ca < 18) return "No Cumple";
    case URL_NAMES_V2.MODULO_II: // Tiempo de Reacción / Reaccionar Videos
      if (ca >= 7) return "Cumple"; 
      if (ca < 7) return "No Cumple"; 
    case URL_NAMES_V2.MODULO_III: // Tiempo de Percepción y analisis de Riesgo / Videos con cuadrantes
      if (ca >= 13) return "Cumple";
      if (ca < 13) return "No Cumple";
    case URL_NAMES_V2.MODULO_IV: // Evaluacion de Actitud y conducta / Cuestionario 24 preguntas
      return "Cumple";
    case URL_NAMES_V2.MODULO_V: // Evaluacion Psicologica /  Cuestionario 83 preguntas
      return "Cumple";
    case URL_NAMES.CERTIFICATE:
      if (final.length < 3) return "Reprobado";
      if (final.includes("No Cumple")) return "Reprobado";

      let med = final.filter((x) => x === "Cumple Medianamente").length;

      if (med >= 2) return "Reprobado";
      else return "Aprobado";
    default:
  }
};
