import React, { useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import config from "./config.json";
import { useSnackbar } from "notistack";
import { useAuth } from "../../provider/auth";
import axios from "axios";
import { /* COOKIES, REACT_APP_URL, */ URL_NAMES, URL_NAMES_V2 } from "../../utils/state";
import { useApp } from "../../provider/app";
import { Loader } from "../../components/loader";
import { defineApproved } from "../../utils";
import { InatransPlayerCompleted } from "../module-completed";
import { fetchTracking } from "../../services";
import timerIcon from "../../assets/alarm.svg";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import useTimer from "../../hooks/useTimer";

const url = "https://tipconsola.iapp.cl/api/tracking";
const positionStyles = {
  1.1: {
    top: "40%",
    left: "58.2%",
  },
  1.2: {
    top: "39.%",
    left: "35.2%",
  },
  1.3: {
    top: `47%`,
    left: `22.3%`,
  },
  2.1: {
    top: "37.6%",
    left: "58.6%",
  },
  2.2: {
    top: "40.3%",
    left: "41.9%",
  },
  2.3: {
    top: "73.6%",
    left: "8.6%",
  },
  3.1: {
    top: "41%",
    left: "36.9%",
  },
  3.2: {
    top: "26%",
    left: "70%",
  },
  3.3: {
    top: "76%",
    left: "49.5%",
  },
  4.1: {
    top: "63.3%",
    left: "35.4%",
  },
  4.2: {
    top: "65.3%",
    left: "17%",
  },
  4.3: {
    top: "65.4%",
    left: "84.7%",
  },
  5.1: {
    top: "61.7%",
    left: "39.5%",
  },
  5.2: {
    top: "59.9%",
    left: "53%",
  },
  5.3: {
    top: "79%",
    left: "72.6%",
  },
  6.1: {
    top: "26.6%",
    left: "35.1%",
  },
  6.2: {
    top: "49.2%",
    left: "69.5%",
  },
  6.3: {
    top: "14.3%",
    left: "59.3%",
  },
  7.1: {
    top: "72.5%",
    left: "61.4%",
  },
  7.2: {
    top: "56.3%",
    left: "69.5%",
  },
  7.3: {
    top: "33%",
    left: "55.9%",
  },
  8.1: {
    top: "630px",
    left: "702px",
  },
  8.2: {
    top: "60.7%",
    left: "60.6%",
  },
  8.3: {
    top: "69.7%",
    left: "10.5%",
  },
  9.1: {
    top: "75.3%",
    left: "65.5%",
  },
  9.2: {
    top: "64%",
    left: "38.4%",
  },
  9.3: {
    top: "50%",
    left: "55.8%",
  },
  10.1: {
    top: "82%",
    left: "48%",
  },
  10.2: {
    top: "28.2%",
    left: "31.9%",
  },
  10.3: {
    top: "33%",
    left: "45%",
  },
  11.1: {
    top: "41%",
    left: "61.2%",
  },
  11.2: {
    top: "24%",
    left: "53.7%",
  },
  11.3: {
    top: "44.7%",
    left: "35.3%",
  },
  12.1: {
    top: "83.1%",
    left: "57.7%",
  },
  12.2: {
    top: "47.3%",
    left: "29.2%",
  },
  12.3: {
    top: "40.5%",
    left: "54.7%",
  },
  13.1: {
    top: "57.5%",
    left: "86%",
  },
  13.2: {
    top: "60.8%",
    left: "64%",
  },
  13.3: {
    top: "35.6%",
    left: "92.5%",
  },
  14.1: {
    top: "39.8%",
    left: "94.3%",
  },
  14.2: {
    top: "31.5%",
    left: "59.5%",
  },
  14.3: {
    top: "78.5%",
    left: "75.2%",
  },
  15.1: {
    top: "66.5%",
    left: "82.1%",
  },
  15.2: {
    top: "47.3%",
    left: "67.2%",
  },
  15.3: {
    top: "38.8%",
    left: "20.3%",
  },
  16.1: {
    top: "46%",
    left: "89.2%",
  },
  16.2: {
    top: "14%",
    left: "48%",
  },
  16.3: {
    top: "56%",
    left: "55%",
  },
  17.1: {
    top: "39.5%",
    left: "55.3%",
  },
  17.2: {
    top: "31.7%",
    left: "37.3%",
  },
  17.3: {
    top: "57.4%",

    left: "19.5%",
  },
  18.1: {
    top: "18%",
    left: "77%",
  },
  18.2: {
    top: "18%",
    left: "13.7%",
  },
  18.3: {
    top: "69.5%",
    left: "24.5%",
  },
  19.1: {
    top: "38%",
    left: "66.5%",
  },
  19.2: {
    top: "41%",
    left: "93.8%",
  },
  19.3: {
    top: "31%",
    left: "28.3%",
  },
  20.1: {
    top: "26.5%",
    left: "42.8%",
  },
  20.2: {
    top: "74.7%",
    left: "54.5%",
  },
  20.3: {
    top: "32.6%",
    left: "70.4%",
  },
};

export const ModuleIIView = () => {
  const { auth, config } = useAuth();
  const [completed, setCompleted] = React.useState(false);
  const [isAware, setAware] = React.useState(false);
  const [startDate] = React.useState(new Date().toLocaleDateString());
  const [imgIndex, setImgIndex] = React.useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ]);

  useEffect(() => {
    if (auth.user.user_id /* && auth.cookies.intentos !== "test" */) {
      // track = true
      fetchTracking(config).then((data) => {
        if (data >= auth.cookies.intentos) {
          setCompleted(data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Inicio logica para randomizar imagenes y respectivas respuestas */

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  React.useEffect(() => {
    console.log("order: ", localStorage.getItem("order"));
    if (!localStorage.getItem("order")) {
      let new_order = shuffleArray(imgIndex);
      localStorage.setItem("order", new_order);
    } else {
      let storageArray = localStorage.getItem("order").split(",");
      let newImgIndex = storageArray.map((item) => parseInt(item, 10));
      setImgIndex(newImgIndex);
    }
    //eslint-disable-next-line
  }, []);

  /* Fin logica para randomizar imagenes y respectivas respuestas */

  return (
    <>
      {completed ? (
        <InatransPlayerCompleted url_name="inatrans-tiempo-de-reaccion"/>
      ) : (
        <RiskInterceptorView imgIndex={imgIndex} startDate={startDate} />
      )}
    </>
  );
};

const RiskInterceptorView = ({ imgIndex, startDate }) => {
  const [intro, setIntro] = React.useState(true);

  return (
    <>
      {intro ? (
        <IntroRiskInterceptor setIntro={setIntro} />
      ) : (
        <RiskInterceptorViewComponent
          imgIndex={imgIndex}
          startDate={startDate}
        />
      )}
    </>
  );
};

const IntroRiskInterceptor = ({ setIntro }) => {
  return (
    <Dialog
      open={true}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: "#17181a",
          boxShadow: "none",
        },
      }}
    >
      <Dialog
        open={true}
        PaperProps={{
          style: {
            backgroundColor: "#17181a",
            boxShadow: "none",
            padding: "0 1rem",
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-white text-center" id="alert-dialog-title">
          {"Instrucciones Identificación de riesgo"}
        </DialogTitle>
        <DialogContent>
          <div>
            <p className="text-white text-justify mb-4">
            La siguiente prueba mide, a través de una imagen, su capacidad para reconocer situaciones peligrosas que pueden existir durante la conducción. Usted debe reconocer y evaluar cuál es la situación que tiene la mayor probabilidad de generar un incidente o accidente.
            </p>
            <span style={{ fontWeight: "bolder" }} className="text-white mt-4">
              Instrucciones:
            </span>
      <ul
        style={{
          color: "#fff",
          fontWeight: "300",
          textAlign: "left",
          marginBottom: "1rem",
          listStyleType: "inherit",
          marginLeft: "1rem",
        }}
      >
        <li>
        Antes de comenzar, aparecerá un ensayo que le permitirá hacerse una idea de cómo son los siguientes casos. Este ejercicio no se contabilizará en el puntaje final de la prueba.
        </li>
        <li>
        Antes de responder, debe observar cada imagen con atención, en cada una aparecerán tres letras (A; B; C) sobre distintas situaciones que corresponden a un riesgo. Usted debe elegir la situación más peligrosa a través de la letra que la identifica.
        </li>
        <li>
        Debe seleccionar <span style={{ fontWeight: "bolder" }} > SOLO UNA OPCIÓN.</span>
        </li>
        <li>
        Recuerde que usted debe seleccionar <span style={{ fontWeight: "bolder" }} > la situación más peligrosa </span> representada como un objeto, persona o condición climática, que pueda ser causante de un incidente o accidente.
        </li>
        <li>
        Contará con 5 minutos para realizar la prueba.
        </li>
        <li>
        Al finalizar, debe hacer clic en terminar intento para enviar sus respuestas.
        </li>
      </ul>



            {/* <p className="text-white text-justify mb-4">
              El siguiente test mide su capacidad para reconocer situaciones
              peligrosas. Usted debe observar cada imagen con atención asumiendo
              la perspectiva desde afuera y no como un conductor dentro del
              contexto, y seleccionar UNA OPCIÓN, a través de las letras A, B, o
              C, para identificar la situación más peligrosa representada con un
              objeto, persona o condición climática que pueda ser causante de
              una situación de peligro. Para esto, usted cuenta con 5 minutos
              que se cronometran al iniciar el test. Al finalizar, debe hacer
              clic en terminar intento para enviar sus respuestas.
            </p> */}
            <span style={{ fontWeight: "bolder" }} className="text-white mt-4">
              Atención:
            </span>
            <ul className="text-white text-justify mb-4 ">
              <li>
                Recomendamos aumentar el brillo de su pantalla para una mejor
                experiencia.
              </li>
              <li>
                Antes de comenzar, lea cuidadosamente las instrucciones de cada
                evaluación.
              </li>
              <li>
                Si abandona, o recarga la página, las preguntas se reordenarán y
                se perderá el progreso.
              </li>
            </ul>
          </div>
        </DialogContent>
        <DialogActions style={{ color: "#fff" }}>
          <Button onClick={() => setIntro(false)}>Continuar</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

const RiskInterceptorViewComponent = ({ imgIndex, startDate }) => {
  localStorage.getItem("order") && localStorage.removeItem("order");
  const [timer, setTimer] = React.useState(null);
  const [cron, setCron] = React.useState(
    /*  localStorage.getItem("countdown") ? localStorage.getItem("countdown") :  */ 2360, // 360
  );
  /*   const imgIndex = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ]; */
  const [answers, setAnswers] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userAnswers = { ...localStorage };
  const { app, appActions } = useApp();
  const auth = useAuth();
  const questionTimer = useTimer(0);

  React.useEffect(() => {
    questionTimer.start();
  }, [answers]);

  React.useEffect(() => {
    let interval = setInterval(async () => {
      let countdown = cron - 1;
      if (countdown <= 0) {
        setCron(0);
        setTimer(0);
        clearInterval(interval);
        return await handleSubmit();
      }
      setCron(countdown);
      setTimer(createCron(countdown));
      // eslint-disable-next-line
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [cron]);

  function createCron(countdown) {
    let hours = Math.floor(countdown / 3600);
    let minutes = Math.floor((countdown - hours * 3600) / 60);
    let seconds = Math.floor(countdown - hours * 3600 - minutes * 60);

    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }:${seconds < 10 ? "0" + seconds : seconds}`;
  }

  const formatOutputAnswers = (data) => {
    let output = [];
    let entries = Object.entries(data);
    console.log({ entries });
    entries.forEach((element) => {
      let clean = {};
      let keys = element[0];
      let values = element[1].split(";");
      clean["key"] = keys;
      clean["value"] = JSON.parse(values[0]);
      clean["correct"] = JSON.parse(values[1]);
      clean["responseTime"] = JSON.parse(values[2]);
      output.push(clean);
    });
    console.log({ output });
    return output;
  };

  const handleSubmit = async () => {
    let output = formatOutputAnswers(userAnswers);
    let correctAnswers = output.filter((value) => value.correct).length;
    let approved = defineApproved({
      ca: correctAnswers,
      name: URL_NAMES_V2.MODULO_I,
    });
    console.log({
      client: auth.config.client,
      provider: auth.config.provider,
      course_name: auth.auth.cookies.course_name,
      course_id: auth.config.course_id,
      user_id: auth.config.user_id,
      user_name: auth.auth.cookies.user_name,
      url_name: auth.auth.cookies.url_name,
      url_cmid: auth.auth.cookies.url_cmid,
      results: JSON.stringify(answers),
      start_date: startDate,
      end_date: new Date().toLocaleDateString(),
      module_results: {
        module_name: "ina1",
        totalanswers: 20,
        correct: correctAnswers,
        incorrect: 20 - correctAnswers,
        results_detail: output,
        results: approved,
        elapsedSeconds: cron <= 0 ? 360 : Math.abs(cron - 360), // NUEVO, tiempo que tardó en terminar la actividad.
      },
    });
    await axios
      .post(url, {
        client: auth.config.client,
        provider: auth.config.provider,
        course_name: auth.auth.cookies.course_name,
        course_id: auth.config.course_id,
        user_id: auth.config.user_id,
        user_name: auth.auth.cookies.user_name,
        url_name: auth.auth.cookies.url_name,
        url_cmid: auth.auth.cookies.url_cmid,
        results: JSON.stringify(answers),
        start_date: startDate,
        end_date: new Date().toLocaleDateString(),
        module_results: JSON.stringify({
          module_name: "ina1",
          totalanswers: 20,
          correct: correctAnswers,
          incorrect: 20 - correctAnswers,
          results_detail: output,
          results: approved,
          elapsedSeconds: cron <= 0 ? 360 : Math.abs(cron - 360), // NUEVO, tiempo que tardó en terminar la actividad.
        }),
      })
      .then((data) => {
        console.log(data);
        //mostrar alerta publicacion exitosa
        enqueueSnackbar(`Formulario enviado`, { variant: "success" });
        //luego mostrar la pantalla de publicacion exitosa, luego un boton para redireccionar a la proxima evaluacion
        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(`Error al enviar Formulario`, { variant: "error" });
        alert(
          "ERROR AL ENVIAR EL FORMULARIO, CONTACTESE CON SERVICIO AL CLIENTE !",
        );
        setError(true);
      });
  };

  React.useEffect(() => {
    enqueueSnackbar(`Cada imagen dispone de 1 intentos`, { variant: "info" });
    for (let i = 1; i <= 20; i++) {
      localStorage.removeItem(`${i}`);
    }
    // localStorage.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = {
    wrapper: {
      padding: "70px 0px",
      width: "100vw",
      minHeight: "100vh",
      height: "100%",
    },
    header: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      borderBottomWidth: 0.1,
      borderBottomColor: "rgb(202 208 215)",
      borderBottomStyle: "solid",
      padding: "0 0 12px 0",
    },
    headerText: {
      textTransform: "uppercase",
      color: "rgb(202 208 215)",
      fontWeight: 700,
      fontFamily: "Roboto,sans-serif",
      letterSpacing: ".02rem",
    },
    headerTextLight: {
      fontFamily: "Roboto,sans-serif",
      color: "rgb(202, 208, 215)",
      fontSize: ".75rem",
      fontWeight: 700,
      letterSpacing: ".06rem",
      lineHeight: "1rem",
      textTransform: "uppercase",
    },
    subtitle: {
      fontFamily: "Roboto,sans-serif",
      color: "rgb(202 208 215)",
      fontWeight: 700,
    },
    subtitleLight: {
      marginTop: 12,
      fontFamily: "Roboto,sans-serif",
      color: "rgb(202 208 215)",
      fontWeight: 400,
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
    },
    completed: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      marginTop: "20%",
      padding: "1.5rem",
    },
    topCompleted: {
      color: "white",
      backgroundColor: "rgb(52, 152, 219)",
      padding: "1rem",
      boxShadow: "5px 5px 12px 5px #9d9b9b",
      borderBottom: "1px solid #1979ac",
    },
    bottomCompleted: {
      margin: "1rem",
      flexDirection: "column",
      color: "black",
      fontWeight: "500",
    },
    next: {
      backgroundColor: "rgb(52, 152, 219)",
      display: "flex",
      color: "white",
      justifyContent: "center",
      padding: "0.5rem 1.5rem",
    },
    navigateButton: {
      display: "flex",
      color: "black",
      justifyContent: "center",
      padding: "0.5rem 1.5rem",
      cursor: "pointer",
    },
  };

  React.useEffect(() => {
    if (!submitted) {
      window.onbeforeunload = function (event) {
        return "Si abandonas o recargas la páginas, las preguntas ser reordenarán y se perderá el progreso.";
      };
    } else {
      window.onbeforeunload = function (event) {
        // return "Si abandonas o recargas la páginas, las preguntas ser reordenarán y se perderá el progreso.";
      };
    }
  }, [submitted]);

  return (
    <>
      <p
        style={{
          backgroundColor: "rgb(52, 152, 219)",
          padding: ".2rem 1rem",
          borderRadius: "10px",
          position: "absolute",
          zIndex: 999999999999,
          top: 70,
          left: 40,
        }}
      >
        <img
          src={timerIcon}
          style={{ marginRight: 10, marginBottom: 3 }}
          alt="..."
        />
        {timer}
      </p>
      <div style={styles.wrapper} className="max-w-[1240px]">
        {app.loading ? (
          <Loader app={app} appActions={appActions} />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            {!submitted &&
              imgIndex.map((img, idx) => {
                return (
                  <ImageComponent
                    img={img}
                    key={idx}
                    currentImg={idx}
                    answers={answers}
                    setAnswers={setAnswers}
                    correct={config.data[img - 1].correct}
                    timer={questionTimer}
                  />
                );
              })}
            {answers.length === 20 && !submitted && (
              <div style={styles.completed}>
                <h1 style={styles.topCompleted}>
                  20 preguntas completadas{" "}
                  <CheckCircleIcon
                    sx={{ color: "#6dbb6d", fontSize: "40px" }}
                  />
                </h1>
                <p style={styles.bottomCompleted}>
                  Para confirmar y enviar pulse el boton:
                </p>
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Enviar
                </button>
              </div>
            )}
            {submitted && !error && (
              <div style={styles.completed}>
                <h1 style={styles.topCompleted}>Formulario Enviado</h1>
                <div
                  className="linkNavigate flex items-center"
                  style={styles.navigateButton}
                >
                  Para ingresar a otros modulos, regresa a campus virtual&nbsp;
                  <a
                    style={{ textDecoration: "underline" }}
                    href={`http://elearning.inatrans.cl/course/view.php?id=${auth.auth.cookies.course_id}`}
                  >
                    <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                      aquí
                    </Button>
                  </a>
                </div>
                <div className="flex flex-col">
                  <p className="lead mt-3 text-black">
                    Para continuar al siguiente módulo:{" "}
                    <a
                      style={{ textDecoration: "underline" }}
                      href={`https://tipconsola.inatrans.cl/?course_id=123123123&course_name=curso_beta&user_id=6901&user_name=Tester&user_email=agu.quiroz%40tipddy.cl&url_cmid=1414141413&url_name=inatrans-identificacion-de-riesgos&config=inatrans&intentos=test`}
                    >
                      <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                        aquí
                      </Button>
                    </a>
                  </p>
                  {
                    <p className="lead mt-3 text-black">
                      Para regresar a la lista de actividades:{" "}
                      <a
                        style={{
                          textDecoration: "underline",
                          marginLeft: "5px",
                        }}
                        href={`https://tipconsola.inatrans.cl/?config=modulelist&course_id=123123123&course_name=Evaluación%20Aversión%20al%20riesgo%20para%20conductores%2023&user_id=37927&user_name=Agustin%20Quiroz&url_cmid=26005&user_email=agu.quiroz%40tipddy.cl&url_name=modules`}
                      >
                        <Button className="!bg-azul !text-white !p-1 hover:!bg-azul/75">
                          aquí
                        </Button>
                      </a>
                    </p>
                  }
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const ImageComponent = ({
  img,
  answers,
  setAnswers,
  correct,
  currentImg,
  timer,
}) => {
  const isCurrent = answers.length === currentImg ? true : false;
  const [, setFinished] = React.useState(false);
  const [, setMaxAttempts] = React.useState(false);
  const [counter, setCounter] = React.useState(0);
  const [res, setRes] = React.useState(null);
  const [isCorrect, setCorrect] = React.useState(null);
  const [starProgress, setStarProgress] = React.useState(10);
  const [completed, setCompleted] = React.useState(0);
  const [selectedA, setSelectedA] = React.useState(null);
  const [selectedB, setSelectedB] = React.useState(null);
  const [selectedC, setSelectedC] = React.useState(null);
  const [selectedAnswer, setSelectedAnswer] = React.useState({
    a: false,
    b: false,
    c: false,
  });
  const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  let needNumber = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];

  const styles = {
    1.1: {
      // ok
      position: "absolute",
      top: `26%`, //"40%",
      height: "8.5vw",
      width: "8.5vw",
      left: "52.2%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    1.2: {
      position: "absolute",
      top: "25%",
      height: "8.5vw",
      width: "8.5vw",
      left: "32.7%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    1.3: {
      position: "absolute",
      top: "39.7%",
      height: "8.5vw",
      width: "8.5vw",
      left: "23.3%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    2.1: {
      // ok
      position: "absolute",
      top: "33.6%",
      height: "8.5vw",
      width: "8.5vw",
      left: "54.6%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    2.2: {
      position: "absolute",
      top: "35.5%",
      height: "8.5vw",
      width: "8.5vw",
      left: "39.9%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    2.3: {
      position: "absolute",
      top: "71.6%",
      height: "8.5vw",
      width: "8.5vw",
      left: "10.6%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    3.1: {
      //ok
      position: "absolute",
      top: "35%",
      height: "8.5vw",
      width: "8.5vw",
      left: "35.9%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    3.2: {
      position: "absolute",
      top: "21%",
      height: "8.5vw",
      width: "8.5vw",
      left: "69%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    3.3: {
      position: "absolute",
      top: "70%",
      height: "8.5vw",
      width: "8.5vw",
      left: "48.5%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    4.1: {
      // ok
      position: "absolute",
      top: "57.3%",
      height: "8.5vw",
      width: "8.5vw",
      left: "33.4%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    4.2: {
      position: "absolute",
      top: "61.3%",
      height: "8.5vw",
      width: "8.5vw",
      left: "16%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    4.3: {
      position: "absolute",
      top: "60.4%",
      height: "8.5vw",
      width: "8.5vw",
      left: "81.7%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    5.1: {
      //ok
      position: "absolute",
      top: "56.7%",
      height: "8.5vw",
      width: "8.5vw",
      left: "36.5%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    5.2: {
      position: "absolute",
      top: "54.9%",
      height: "8.5vw",
      width: "8.5vw",
      left: "51%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    5.3: {
      position: "absolute",
      top: "72%",
      height: "8.5vw",
      width: "8.5vw",
      left: "69.6%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    6.1: {
      position: "absolute",
      top: "22.6%",
      height: "8.5vw",
      width: "8.5vw",
      left: "33.1%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    6.2: {
      position: "absolute",
      top: "45.2%",
      height: "8.5vw",
      width: "8.5vw",
      left: "68.5%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    6.3: {
      position: "absolute",
      top: "11.3%",
      height: "8.5vw",
      width: "8.5vw",
      left: "57.3%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    7.1: {
      // ok
      position: "absolute",
      top: "70.5%",
      height: "8.5vw",
      width: "8.5vw",
      left: "55.5%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    7.2: {
      position: "absolute",
      top: "49.3%",
      height: "8.5vw",
      width: "8.5vw",
      left: "60.5%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    7.3: {
      position: "absolute",
      top: "26%",
      height: "8.5vw",
      width: "8.5vw",
      left: "51.9%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",

      color: "white",
    },
    8.1: {
      // ok
      position: "absolute",
      top: "73.3%",
      height: "8.5vw",
      width: "8.5vw",
      left: "50%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    8.2: {
      position: "absolute",
      top: "55.7%",
      height: "8.5vw",
      width: "8.5vw",
      left: "58.6%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    8.3: {
      position: "absolute",
      top: "64.7%",
      height: "8.5vw",
      width: "8.5vw",
      left: "8.5%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    9.1: {
      // ok
      position: "absolute",
      top: "68.3%",
      height: "8.5vw",
      width: "8.5vw",
      left: "63.5%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    9.2: {
      position: "absolute",
      top: "57%",
      height: "8.5vw",
      width: "8.5vw",
      left: "36.4%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    9.3: {
      position: "absolute",
      top: "44%",
      height: "8.5vw",
      width: "8.5vw",
      left: "53.8%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    10.1: {
      // ok
      position: "absolute",
      top: "77%",
      height: "8.5vw",
      width: "8.5vw",
      left: "46%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    10.2: {
      position: "absolute",
      top: "24.2%",
      height: "8.5vw",
      width: "8.5vw",
      left: "29.9%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    10.3: {
      position: "absolute",
      top: "29%",
      height: "8.5vw",
      width: "8.5vw",
      left: "43%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",

      color: "white",
    },
    11.1: {
      //ok
      position: "absolute",
      top: "37%",
      height: "8.5vw",
      width: "8.5vw",
      left: "59.2%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    11.2: {
      position: "absolute",
      top: "20%",
      height: "8.5vw",
      width: "8.5vw",
      left: "51.7%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    11.3: {
      position: "absolute",
      top: "40.7%",
      height: "8.5vw",
      width: "8.5vw",
      left: "32.3%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",

      color: "white",
    },
    12.1: {
      // ok
      position: "absolute",
      top: "76.1%",
      height: "8.5vw",
      width: "8.5vw",
      left: "55.7%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    12.2: {
      position: "absolute",
      top: "42.3%",
      height: "8.5vw",
      width: "8.5vw",
      left: "27.2%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    12.3: {
      position: "absolute",
      top: "35.5%",
      height: "8.5vw",
      width: "8.5vw",
      left: "52.7%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    13.1: {
      // ok
      position: "absolute",
      top: "54.5%",
      height: "8.5vw",
      width: "8.5vw",
      left: "84.2%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    13.2: {
      position: "absolute",
      top: "58.8%",
      height: "8.5vw",
      width: "8.5vw",
      left: "62.8%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    13.3: {
      position: "absolute",
      top: "32.6%",
      height: "8.5vw",
      width: "8.5vw",
      left: "90.5%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    14.1: {
      // ok
      position: "absolute",
      top: "35.8%",
      height: "8.5vw",
      width: "8.5vw",
      left: "90.3%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    14.2: {
      position: "absolute",
      top: "28.5%",
      height: "8.5vw",
      width: "8.5vw",
      left: "57.7%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    14.3: {
      position: "absolute",
      top: "75.9%",
      height: "8.5vw",
      width: "8.5vw",
      left: "72.2%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    15.1: {
      //ok
      position: "absolute",
      top: "62.5%",
      height: "8.5vw",
      width: "8.5vw",
      left: "80.1%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    15.2: {
      position: "absolute",
      top: "42.3%",
      height: "8.5vw",
      width: "8.5vw",
      left: "64.2%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    15.3: {
      position: "absolute",
      top: "33.8%",
      height: "8.5vw",
      width: "8.5vw",
      left: "18.3%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    16.1: {
      // ok
      position: "absolute",
      top: "40%",
      height: "8.5vw",
      width: "8.5vw",
      left: "86.2%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    16.2: {
      position: "absolute",
      top: "10%",
      height: "8.5vw",
      width: "8.5vw",
      left: "46%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",
    },
    16.3: {
      position: "absolute",
      top: "50%",
      height: "8.5vw",
      width: "8.5vw",
      left: "53%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",
    },
    17.1: {
      //ok
      position: "absolute",
      top: "30.5%",
      height: "8.5vw",
      width: "8.5vw",
      left: "46.3%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    17.2: {
      position: "absolute",
      top: "26.7%",
      height: "8.5vw",
      width: "8.5vw",
      left: "33.3%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    17.3: {
      position: "absolute",
      top: "51.4%",
      height: "8.5vw",
      width: "8.5vw",
      left: "12.5%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",

      color: "white",
    },
    18.1: {
      position: "absolute",
      top: "32%",
      height: "8.5vw",
      width: "8.5vw",
      left: "62%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    18.2: {
      position: "absolute",
      top: "21%",
      height: "8.5vw",
      width: "8.5vw",
      left: "15.7%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    18.3: {
      position: "absolute",
      top: "67.5%",
      height: "8.5vw",
      width: "8.5vw",
      left: "27.5%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",

      color: "white",
    },
    19.1: {
      // ok
      position: "absolute",
      top: "34%",
      height: "8.5vw",
      width: "8.5vw",
      left: "64.5%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    19.2: {
      position: "absolute",
      top: "36%",
      height: "8.5vw",
      width: "8.5vw",
      left: "89.8%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    19.3: {
      position: "absolute",
      top: "27%",
      height: "8.5vw",
      width: "8.5vw",
      left: "25.3%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",

      color: "white",
    },
    20.1: {
      // ok
      position: "absolute",
      top: "23.5%",
      height: "8.5vw",
      width: "8.5vw",
      left: "41.8%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    20.2: {
      position: "absolute",
      top: "63.7%",
      height: "8.5vw",
      width: "8.5vw",
      left: "28.5%",
      borderRadius: "50%",
      zIndex: 10,
      cursor: "pointer",

      color: "white",
    },
    20.3: {
      position: "absolute",
      top: "28.6%",
      height: "8.5vw",
      width: "8.5vw",
      left: "69.4%",
      borderRadius: "50%",
      zIndex: 15,
      cursor: "pointer",

      color: "white",
    },
  };

  const textstyles = {
    light: {
      color: "#fff",
      fontFamily: "Roboto,sans-serif",
      fontWeight: 400,
    },
  };

  const containerStyles = {
    box: {
      padding: 10,
      //margin: 10,
      position: "relative",
      display: isCurrent ? "unset" : "none",
      height: "auto",
      zIndex: "1",
      width: "100%",
    },
    img: {
      // position: "relative",
      //top: 0,
      width: "100%",
      //height: "80%",
      //minHeight: "80vh",
      //border: "2px solid #fff",
      //borderRadius: 15,
    },
    progress: {
      padding: "0 1rem",
      marginTop: "15px",
      backgroundColor: "rgb(52, 152, 219)",
      width: 100,
      height: 40,
      borderRadius: 15,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      border: "1px solid #fff",
    },
    next: {
      marginLeft: "auto",
      height: 40,
      marginTop: "15px",
      display: "flex",
      backgroundColor: "#293e4f",
      width: 100,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 15,
      cursor: "pointer",
      border: "1px solid #fff",
    },
    number1: {
      fontFamily: "Anton",
      color: "white",
      display: "flex",
      justifyContent: "center",
      fontSize: "30px",
      left: ".9rem",
      top: "0",
    },
    number2: {
      fontFamily: "Anton",
      color: "white",
      display: "flex",
      justifyContent: "center",
      fontSize: "30px",
      left: ".8rem",
      top: "0",
    },
    number3: {
      fontFamily: "Anton",
      color: "white",
      display: "flex",
      justifyContent: "center",
      fontSize: "30px",
      left: ".8rem",
      top: "0",
    },
  };

  const handleNext = () => {
    if (res) {
      let temp = answers.filter((r) => r.index !== img);
      let final = [...temp, res].sort((a, b) => a - b);
      timer.reset();
      setAnswers(final);
      setFinished(true);
    }
  };

  const setSelected = (idx) => {
    /* let isFinished = checkAttempts();
        if (isFinished) {
          return;
        } */
    timer.stop(); //
    let keyidx = Number(idx.split(".")[1]);
    console.log(`respuesta en ms: ${timer.elapsedTime}`);
    let responseTime = timer.elapsedTime;
    checkResponse(keyidx, img, responseTime); // NUEVO, RESPUESTAS CON CRONOMETRO, tiempo en que tarda el usuario en responder. ESO SI, YA NO ES NECESARIA EN ESTA ACTIVIDAD
    let data = {
      index: img,
      value: keyidx,
      time: responseTime,
    };
    setRes(data);
  };

  const checkResponse = (response, img, responseTime) => {
    if (response === correct) {
      setStarProgress(140);
      setCorrect(true);
      setCompleted(1);
      localStorage.setItem(img, `${response};true;${responseTime}`);
    } else {
      setCorrect(false);
      setStarProgress(10);
      setCompleted(1);
      localStorage.setItem(img, `${response};false;${responseTime}`);
    }
  };

  /*   const checkAttempts = () => {
        if (counter >= 1) {
          setMaxAttempts(true);
          enqueueSnackbar(
            `${counter} intentos alcanzados, continue con la siguiente imagen`,
            { variant: "error" }
          );
          return true;
        } else {
          let currentCount = counter + 1;
          setCounter(currentCount);
          setMaxAttempts(false);
          return false;
        }
      }; */

  return (
    <div style={containerStyles.box} className="mt-12 md:mt-14 px-4 md:px-4">
      <div
        style={{
          ...styles[`${img}.1`],
          top: `${parseFloat(styles[`${img}.1`].top) - (viewportWidth < 500 ? 6 : viewportWidth < 900 ? 4 : 0)}%`,
        }}
        onClick={() => {
          setSelectedAnswer({
            a: true,
            b: false,
            c: false,
          });
          setSelected(`${img}.1`);
        }}
      >
        {/*         {res && res.value === 1 && (
          <>
            {isCorrect ? (
              <CheckCircleIcon
                sx={{
                  color: "#6dbb6d",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            ) : (
              <CancelIcon
                sx={{
                  color: "red",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            )}
          </>
        )} */}
        {/* {needNumber.includes(img) && (
          <div style={containerStyles.number1}>1</div>
        )} */}
        <div
          style={{ width: "8.5vw", height: "8.5vw" }}
          className={`absolute rounded-full blur-md bg-white ${selectedAnswer.a ? "opacity-80" : "opacity-0"}  transition-all max-w-28 max-h-28`}
        ></div>
      </div>
      <div
        style={{
          ...styles[`${img}.2`],
          top: `${parseFloat(styles[`${img}.2`].top) - (viewportWidth < 500 ? 6 : viewportWidth < 900 ? 4 : 0)}%`,
        }}
        onClick={() => {
          setSelectedAnswer({
            a: false,
            b: true,
            c: false,
          });
          setSelected(`${img}.2`);
        }}
      >
        {/*         {res && res.value === 2 && (
          <>
            {isCorrect ? (
              <CheckCircleIcon
                sx={{
                  color: "#6dbb6d",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            ) : (
              <CancelIcon
                sx={{
                  color: "red",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            )}
          </>
        )} */}
        {/* {needNumber.includes(img) && (
          <div style={containerStyles.number2}>2</div>
        )} */}
        <div
          style={{ width: "8.5vw", height: "8.5vw" }}
          className={`absolute rounded-full blur-md bg-white ${selectedAnswer.b ? "opacity-80" : "opacity-0"}  transition-all max-w-28 max-h-28`}
        ></div>
      </div>
      <div
        style={{
          ...styles[`${img}.3`],
          top: `${parseFloat(styles[`${img}.3`].top) - (viewportWidth < 500 ? 6 : viewportWidth < 900 ? 4 : 0)}%`,
        }}
        onClick={() => {
          setSelectedAnswer({
            a: false,
            b: false,
            c: true,
          });
          setSelected(`${img}.3`);
        }}
      >
        {/*       {res && res.value === 3 && (
          <>
            {isCorrect ? (
              <CheckCircleIcon
                sx={{
                  color: "#6dbb6d",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            ) : (
              <CancelIcon
                sx={{
                  color: "red",
                  fontSize: 25,
                  opacity: 0.8,
                  transform: "translateX(40px) translateY(-10px)",
                  zIndex: 1000,
                }}
              />
            )}
          </>
        )} */}
        {/* {needNumber.includes(img) && (
          <div style={containerStyles.number3}>3</div>
        )} */}
        <div
          style={{ width: "8.5vw", height: "8.5vw" }}
          className={`absolute  rounded-full blur-md bg-white ${selectedAnswer.c ? "opacity-80" : "opacity-0"}  transition-all max-w-28 max-h-28`}
        ></div>
      </div>
      <img
        style={containerStyles.img}
        alt="..."
        className="aspect-[8/5] rounded-lg"
        src={`https://tipddyassets.fra1.cdn.digitaloceanspaces.com/inatrans/optimized/idet-riesgos-updated-${img}.jpg`}
      />
      <div style={{ display: "flex" }}>
        <div style={{ ...containerStyles.progress, marginRight: 30 }}>
          <div
            style={{
              position: "relative",
              height: 15,
              width: 10,
              backgroundColor: "#5cc4f1",
              borderRadius: 10,
              transition: "0.7s ease",
            }}
          ></div>
          <StarIcon
            sx={{
              color: "#fff",
              fontSize: 40,
              marginLeft: "-15px",
              marginBottom: "0.2rem",
              zIndex: 10,
            }}
          />
          <h5 style={textstyles.light}>{completed}/1</h5>
          {/*           <h5 style={textstyles.light}>Tu respuesta: {res.value}</h5> */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <p className="m-0">
            Opcion seleccionada :{" "}
            {res && res.value
              ? res.value == 1
                ? "A"
                : res.value == 2
                  ? "B"
                  : res.value == 3 && "C"
              : ""}
          </p>
        </div>
        {res && (
          <div style={containerStyles.next} onClick={handleNext}>
            <h5 style={textstyles.light}>Siguiente</h5>
          </div>
        )}
      </div>
    </div>
  );
};
